<template>
  <mobile-screen :header="true" screen-class="gray-bg fluid one-click-screen">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="one-click-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name:
                $route.params && $route.params.backLinkName
                  ? $route.params.backLinkName
                  : 'r_one-click'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("one-click", "map", "map") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section
      class="map-iframe-wrapper"
      style="display: flex; width: 100%; position: relative; height: 100%"
      v-if="mapUrl"
    >
      <iframe
        ref="mapIframe"
        style="position: absolute; top: 0; left: 0; width: 100%; bottom: 0; border: none; overflow: auto"
        :src="mapUrl"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </section>
    <template v-slot:footer>
      <section class="plans-footer-menu icon-foo1">
        <nav v-if="showDeclareIcon" class="menu">
          <ul class="menu-items">
            <li class="menu-item central-item icon-foo2">
              <button @click="showModal = true">
                <span class="svg-icon"
                  ><icon icon="#cx-foo2-submit-38x38"
                /></span>
              </button>
            </li>
          </ul>
        </nav>
      </section>
    </template>
    <screen-modal
      v-if="showModal"
      class="confirm-modal"
      type="success"
      :confirm-button-label="displayLabelName('plan', 'plan', 'ok')"
      :cancel-button-label="displayLabelName('plan', 'plan', 'cancel')"
      :confirm-action="declareSlot"
      :show="showModal"
      @close="showModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("plan", "plan", "declaration-confirmed") }}
      </h3>
      <template v-if="type && slots">
        <p class="text">
          <time
            v-if="['DAY', 'HALF_DAY'].includes(type)"
            :datetime="displayDate(slots[0].datetime_from)"
            >{{ displayDate(slots[0].datetime_from) }}</time
          >
          <time
            v-else
            :datetime="
              `${displayTime(slots[0].datetime_from)} - ${displayTime(
                slots[0].datetime_to
              )}`
            "
            >{{ displayTime(slots[0].datetime_from) }} -
            {{ displayTime(slots[0].datetime_to) }}</time
          >
        </p>
        <p class="text">{{ name }}</p>
      </template>
    </screen-modal>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import PlanMixin from "@/services/mixins/plan/plan-mixin";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import qs from "qs";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";

export default {
  name: "OneClickMapPage",
  created() {
    if (!this.mapUrl) {
      this.$router.push({ name: this.backLinkName || "r_one-click" });
    }
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });
    window.addEventListener("message", this.readMessage, false);
  },
  data() {
    return {
      showModal: false,
      showDeclareIcon: false,
      resourceId: null,
      slots: null,
      type: null,
      name: null
    };
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("oneClick", ["mapUrl"]),
    ...mapState("resource", ["resource"]),
    ...mapState("plan", ["mapData"]),
    routeName() {
      return this.$route.name;
    }
  },
  mixins: [PlanMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  methods: {
    ...mapActions("plan", ["selectPlanLevel", "selectPlanResource"]),
    ...mapActions("resource", ["getResource"]),
    readMessage(event) {
      this.selectPlanResource(null);
      this.selectPlanLevel(null);
      const envVariables = process.env;
      const { data } = event;
      const { VUE_APP_5DMAPS_URL } = envVariables;
      if (event.origin === VUE_APP_5DMAPS_URL) {
        const levelId = data.levelId;
        this.resourceId = data.resourceId;
        const eventName = data.eventName;
        if (data.slots && data.type) {
          this.slots = data.slots;
          this.type = data.type;
          this.selectSlot(this.slots, this.type);
        }
        if (data.name) {
          this.name = data.name;
        }
        if (eventName === "resourceChange") {
          this.showDeclareIcon = false;
        }
        if (
          levelId &&
          (eventName === "calendarOpen" ||
            (this.routeName === "r_one-click-calendar" &&
              eventName === "resourceChange"))
        ) {
          this.selectPlanLevel({
            includes: ["default", "directly_declarable", "resources_count"],
            level_id: levelId,
            noLoaders: true
          }).then(() => {
            if (this.resourceId) {
              this.getResource({ id: this.resourceId, noLoaders: true }).then(
                () => {
                  setTimeout(() => {
                    this.selectPlanResource(this.resource.data).then(() => {
                      this.$router.push({
                        name: "r_one-click-calendar"
                      });
                    });
                  }, 0);
                }
              );
            } else {
              this.$router.push({ name: "r_one-click-calendar" });
            }
          });
        }
        if (eventName === "makeDeclaration") {
          this.showDeclareIcon = true;
        }
      }
    },
    selectSlot(slots, type) {
      localStorage.setItem(
        "mapSlots",
        JSON.stringify({
          slots: slots,
          type: type,
          simpleSlots: true
        })
      );
    },
    declareSlot() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      const dataToSend = {
        slots: this.slots,
        type: this.type
      };
      httpServiceAuth
        .post(
          `${apiEndpoints.company.declarations}/resources/${this.resourceId}`,
          dataToSend
        )
        .then(() => {
          const mapData = qs.stringify({
            declarationCreated: 1
          });
          this.$refs.mapIframe.contentWindow.postMessage(
            {
              call: "declarationCreated",
              value: mapData
            },
            "*"
          );
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.showDeclareIcon = false;
          this.$store.commit("loader/setScreenLoading", false, {
            root: true
          });
        });
    },
    displayDate(date) {
      if (!date) {
        return;
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return;
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    }
  },
  watch: {
    mapData(val) {
      if (val) {
        this.showDeclareIcon = false;
        this.$refs.mapIframe.contentWindow.postMessage(
          {
            call: "mapSlots",
            value: val
          },
          "*"
        );
      }
    }
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
    window.removeEventListener("message", this.readMessage);
  }
};
</script>
